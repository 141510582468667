import html from "html-literal";
import logo from "../assets/img/sweet-label.png";
import crewPhoto from "../assets/img/cards-crew.jpg";

export default (st) => html`
  <div class="ui container large-font-size">
    <!-- <div class="logo-background"></div> -->
    <img src="${crewPhoto}" alt="Photo of the owners" class="ui centered medium image">
    <h2 class="logo-font large-padding-top ui header">About Dawg Haus Grill'n Sauce</h2>
    <p>
      It began the summer of 2010 when my husband was becoming more and more famous at church socials for his smoked wings.
      He was never happy with the sauces on the market.  So, together we began working on our homemade Grill'n Sauce.
      We have developed two flavors "Sweet" and "Hot".  They were both big hits!
    </p>
    <p>
      After coaxing from close family and friends, we made it available to the public the summer of 2021.
    </p>
    <h2 class="logo-font large-padding-top ui header ">It's Howlin' Good!!!</h2>
    <p>
      More than a decade was spent perfecting a sauce that will set any backyard BBQ "aglaze"!
    </p>
    <p>
      Our sweet Grill'n Sauce is bursting with honey, brown sugar and tangy, smokey goodness.
    </p>
    <p>
      The Hot Grill'n Sauce starts out sweet and finishes with a kick! But don't worry, it's not too hot, it's a "mid-western" hot.
    </p>
    <div class="fb-page" data-href="https://www.facebook.com/DawgHausGrillnsauce" data-tabs="timeline" data-width="500" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
      <blockquote cite="https://www.facebook.com/DawgHausGrillnsauce" class="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/DawgHausGrillnsauce">Dawg Haus</a>
      </blockquote>
    </div>
  </div>
`;