import { Header, Main, Footer } from "./components";
import * as state from "./store";
import Navigo from "navigo";
import { capitalize, has, lowerCase, merge } from "lodash";

// Workaround for the ical.js package module import issue
window.ICAL = {};

const router = new Navigo("/");

router.hooks({
  before: (done, params) => {
    const view = params && params.data && params.data.hasOwnProperty("view") ? capitalize(params.data.view) : "Home";
    done();
  }
});

router
  .on({
    "/": () => render(merge(state.Global, state.Home)),
    "/:view": params => {
      let view = capitalize(params.data.view);
      render(merge(state.Global, state[view]));
    }
  })
  .resolve();

function render(st) {
  document.title = st.tabTitle || state.Global.tabTitle;
  document.querySelector("#root").innerHTML = `
  ${Header(st)}
  ${Main(st)}
  ${Footer()}
`;

  router.updatePageLinks();

  addEventListeners(st);
}

// add menu toggle to bars icon in nav bar
function addEventListeners(st) {
  if (st.view === "Contact") {
    const form = document.getElementById('contactForm');
    const message = document.getElementById('successMessage');
    const messageText = document.getElementById('messageText');

    form.addEventListener("submit", event => {
      event.preventDefault()
      let data = new FormData(event.target);
      fetch(event.target.action, {
        method: form.method,
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        message.classList.remove('hidden');
        message.classList.add('visible');
        form.reset();
      }).catch(error => {
        messageText.innerText = "Oops! There was a problem submitting your form"
        message.classList.remove('hidden');
        message.classList.add('visible');
      });
    });
  }
}