import html from 'html-literal';

export default st => html`
  <div class="ui fixed menu">
    <div class="ui container">
      <a class="header item" href="/" data-navigo>
        <i class="home icon"></i> Dawg Haus Grill'n Sauce
      </a>
      <a class="item" href="/events" data-navigo>
        <i class="calendar icon"></i> Events
      </a>
      <a class="item" href="/contact" data-navigo>
        <i class="mail icon"></i> Contact Us
      </a>
    </div>
  </div>
`