import html from "html-literal";
import { Message } from "../components";

export default (st) => html`
  <div class="ui container">
    <h2>${st.header}</h2>
    ${Message('successMessage', 'Message Status', 'Message has been sent successfully!')}
    <form id="contactForm" action="${process.env.FORMSPREE_URL}" method="POST" class="ui form">
      <input type="hidden" name="form-name" value="contactForm" />
      <div class="field">
        <label>Full Name *</label>
        <input type="text" name="fullName" placeholder="Full Name" required>
      </div>
      <div class="field">
        <label>E-mail *</label>
        <input type="text" name="email" placeholder="E-mail" required>
      </div>
      <div class="field">
        <label>Message</label>
        <textarea name="message"></textarea>
      </div>
      <hr>
      <h4>Request Dawg Haus Grill'n Sauce</h4>
      <div class="field">
        <label>16oz Sweet Grill'n Sauce: $${process.env.PRICE_PER_BOTTLE} each</label>
        <select name="sweet-sauce-quantity" id="sweet-sauce-quantity" class="sauce-qty">
          <option value="None">None</option>
          <option value="1-bottle">1 Bottle</option>
          <option value="2-bottle">2 Bottles</option>
          <option value="3-bottle">3 Bottles</option>
          <option value="4-bottle">4 Bottles</option>
          <option value="5-bottle">5 Bottles</option>
          <option value="6-bottle">6 Bottles</option>
          <option value="7-bottle">7 Bottles</option>
          <option value="8-bottle">8 Bottles</option>
          <option value="9-bottle">9 Bottles</option>
          <option value="10-bottle">10 Bottles</option>
          <option value="11-bottle">11 Bottles</option>
          <option value="1-case">1 Case (12 Bottles/Case)</option>
          <option value="2-case">2 Cases (12 Bottles/Case)</option>
          <option value="3-case">3 Cases (12 Bottles/Case)</option>
          <option value="4-case">4 Cases (12 Bottles/Case)</option>
          <option value="5-case">5 Cases (12 Bottles/Case)</option>
          <option value="6-case">6 Cases (12 Bottles/Case)</option>
          <option value="7-case">7 Cases (12 Bottles/Case)</option>
          <option value="8-case">8 Cases (12 Bottles/Case)</option>
          <option value="9-case">9 Cases (12 Bottles/Case)</option>
          <option value="10-case">10 Cases (12 Bottles/Case)</option>
        </select>
      </div>
      <div class="field">
        <label>16oz Hot Grill'n Sauce: $8.00 each</label>
        <select name="hot-sauce-quantity" id="hot-sauce-quantity" class="sauce-qty">
          <option value="None">None</option>
          <option value="1-bottle">1 Bottle</option>
          <option value="2-bottle">2 Bottles</option>
          <option value="3-bottle">3 Bottles</option>
          <option value="4-bottle">4 Bottles</option>
          <option value="5-bottle">5 Bottles</option>
          <option value="6-bottle">6 Bottles</option>
          <option value="7-bottle">7 Bottles</option>
          <option value="8-bottle">8 Bottles</option>
          <option value="9-bottle">9 Bottles</option>
          <option value="10-bottle">10 Bottles</option>
          <option value="11-bottle">11 Bottles</option>
          <option value="1-case">1 Case (12 Bottles/Case)</option>
          <option value="2-case">2 Cases (12 Bottles/Case)</option>
          <option value="3-case">3 Cases (12 Bottles/Case)</option>
          <option value="4-case">4 Cases (12 Bottles/Case)</option>
          <option value="5-case">5 Cases (12 Bottles/Case)</option>
          <option value="6-case">6 Cases (12 Bottles/Case)</option>
          <option value="7-case">7 Cases (12 Bottles/Case)</option>
          <option value="8-case">8 Cases (12 Bottles/Case)</option>
          <option value="9-case">9 Cases (12 Bottles/Case)</option>
          <option value="10-case">10 Cases (12 Bottles/Case)</option>
        </select>
      </div>
      <div class="notice">
        NOTE: You will be contacted regarding payment and shipping prior to the order being fulfilled.
      </div>
      <div class="notice">
        * - Required Field
      </div>
      <button class="ui button" type="submit">Submit</button>
    </form>
  </div>
  
`;